import { useEffect, useState } from "react";

const useDelayedRender = (delay = 1000) => {
    const [isDelayed, setIsDelayed] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsDelayed(true), delay);
    }, []);

    return {
        isDelayed
    }
};

export default useDelayedRender;