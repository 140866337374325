import styled from "styled-components";
import { Link } from "gatsby"
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledContactUsButton = styled(Link)`
    text-decoration: none;
    display: inline-block;
    color: rgb(255, 255, 255);
    height: 3.5rem !important;
    width: 8rem !important;
    padding: 0px 2rem;
    font-size: 1.125rem !important;
    border: .0625rem solid #fff9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: Nunito Sans;
    line-height: 1.5rem;

    &:hover {
        border-color: var(--main-gold);
    }
`;

export const StyledCenteredContainer = styled.div`
    padding: 0 16rem;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 0 6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        padding: 0 1.5rem;
    }
`;
