import React from "react";
import loadable from "@loadable/component";
import useDelayedRender from "@hooks/useDelayedRender";
import backgroundImage from "@images/extended-reality/background.jpg";
import {
  DELAYED_RENDER_TIME,
  XR_BACKGROUND_IMAGE_TITLE,
  XR_BACKGROUND_IMAGE_DESCRIPTION,
  VIDEO_SECTION_TEXT_ADDITIONAL_STYLES,
} from "./constants";
import { StyledCenteredContainer } from "./styled";

const VideoSection = loadable(() => import("@common/CommonSections/VideoSection/VideoSection"));

const ExperienceCells = loadable(() => import("./components/ExperienceCells"));

const Industries = loadable(() => import("./components/Industries"));

const InlineBenefits = loadable(() => import("./components/InlineBenefits"));

const Services = loadable(() => import("./components/Services"));

const TechnologyStack = loadable(() => import("./components/TechnologyStack"));

const WeWorkWith = loadable(() => import("./components/WeWorkWith"));

const Feedback = loadable(() => import("./components/Feedback"));

const FlippableBenefits = loadable(() => import("./components/FlippableBenefits"));

const CatchUpSection = loadable(() => import("./components/CatchUpSection"));

const ExtendedReality = () => {
  const { isDelayed } = useDelayedRender(DELAYED_RENDER_TIME);

  return (
    <>
      <VideoSection
        backgroundImage={backgroundImage}
        title={XR_BACKGROUND_IMAGE_TITLE}
        description={XR_BACKGROUND_IMAGE_DESCRIPTION}
        textContainerAdditionalStyles={VIDEO_SECTION_TEXT_ADDITIONAL_STYLES}
      />
      {isDelayed && (
        <>
          <StyledCenteredContainer>
            <ExperienceCells />
            <Industries />
            <InlineBenefits />
            <TechnologyStack />
            <WeWorkWith />
          </StyledCenteredContainer>
          <Services />
          <StyledCenteredContainer>
            <Feedback />
            <FlippableBenefits />
          </StyledCenteredContainer>
          <CatchUpSection />
        </>
      )}
    </>
  );
};

export default ExtendedReality;
